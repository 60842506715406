@import "bootstrap";

$link-color: #006bd6;
$wcag-compliant-green: #1E7B34;
$disabled-color: #282a2d;
$disabled-opacity: 0.25;
$theme-colors: (
  primary: #006bd6,
  success: #1E7B34
);

.btn-success {
  @include button-variant($wcag-compliant-green, darken($wcag-compliant-green, 7.5%), darken($wcag-compliant-green, 10%), lighten($wcag-compliant-green,5%), $wcag-compliant-green, darken($wcag-compliant-green,30%));
}

.form-control-rb {
  position: relative;  
  top: 0.3rem;
  width: 1.25rem;
  height: 1.25rem;
  margin-right: 0.75rem;
}

.form-control-checkbox {
  position: relative;
  top: 0.5rem;
  width: 1.55rem;
  height: 1.55rem;
  margin-right: 0.75rem;
}

.form-control-checkbox-label {
  padding-top:0px;
  padding-bottom: 13px;
  padding-left: 6px;
}
