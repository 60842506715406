body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.ms-TooltipHost:hover {
  color: #00bc8c;
  cursor: pointer;
  text-decoration: underline;
}

.ms-Persona:hover {
  cursor: pointer;
}

.iconBtn {
  border: 0;
  background: transparent;
  color: white;
}

.row {
  margin-bottom: 15px;
}

.required-field::after {
  content: " *";
  color: red;
}

.nav-link {
  color: rgb(255, 255, 255, 0.9) !important;
}

.h1 {
  font-size: 18px !important;
}

.h2 {
  font-size: 17px !important;
}

.text-decoration-underline {
  text-decoration: underline;
}

body {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 12 !important;
}
